.TypeIcon {
  position: relative;

  > div {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 0 1px white;
  }

  > img {
    position: relative;
    z-index: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
