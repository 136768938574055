.HPPicker {
  .HPPresetPicker {
    --gap: 0.5em;
    height: 100%;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: auto;
    grid-gap: var(--gap);
    align-items: start;
    justify-content: start;
    align-content: start;

    > .Presets {
      align-self: start;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: auto;
      grid-gap: var(--gap);
      width: 100%;
      flex-grow: 1;
      align-content: flex-start;

      .Value {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      &.Low {
        grid-template-columns: repeat(4, 1fr);
      }

      &.Normal {
        grid-template-columns: repeat(3, 1fr);
      }

      &.High {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }

  .HPInput {
    align-self: start;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 1em;
    margin-top: 1em;

    .Form {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16pt;

      input {
        margin-left: 0.5rem;
        width: 4ch;
        font-family: monospace;
        padding: 10;
        text-align: right;
        margin-right: 10px;
        /* border-radius: 0.5em; */
        border: none;
        padding: 0 0.5ch;
        background-color: rgba(255, 255, 255, 0.25);
        border-bottom: 3px dotted #777;
      }
    }

    .Display {
      font-weight: bold;
      border: 1px solid rgba(0, 0, 0, 0.25);
      padding: 8px;
      border-radius: 8px;

      .Value {
        font-size: var(--font-size);
      }
    }
  }
}
