.Card {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-gap: 0.5em;
  place-items: center;

  > .Buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    place-self: center;

    .Button {
      height: 4em;
      width: 4em;
      border-radius: 1em;
      margin: 0.25em 0.25em;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      font-size: 0.5em;
      font-weight: 800;
      font-size: 9pt;
      background-color: #efefef;
      color: white;
      text-transform: uppercase;
      box-shadow: inset 0 0 0 0.25em rgba(255, 255, 255, 0.5);

      &.DoDamageButton {
        background-color: #c6011f;
      }

      &.HealButton {
        background-color: #4cbb17;
      }

      &.HP-Type {
        background-color: #007fff;
      }

      &.DiscardButton {
        background-color: #333;
        color: rgb(230, 0, 0);
      }
    }
  }
}
