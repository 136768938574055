.Element--Modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  transition-property: filter;
  transition-duration: var(--modal-animation-duration);
  transition-timing-function: ease;
  pointer-events: none;
  display: flex;

  &[data-regressed="true"] {
    > .Content {
      filter: blur(2px);
    }
  }

  &[data-variant="normal"] {
    padding: 1em;
    justify-content: center;
    align-items: center;

    > .Content {
      overflow: scroll;
      background-color: white;
      padding: 1em;
      border-radius: 1.5em;
      width: 100%;
      max-width: 640px;
      max-height: 950px;
      box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.25);
    }
  }

  > .Backdrop {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    filter: opacity(0);
  }

  > .Content {
    width: 100%;
    position: relative;
    z-index: 1;
    --border-radius: 14px;
    border-top-left-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
    transform: translateY(100vh);
    filter: blur(0px);
    transition-property: transform, blur;
    transition-duration: var(--modal-animation-duration);
    transition-timing-function: ease;
  }

  &[data-render-state="CLOSED"] {
    pointer-events: none;
  }

  &[data-render-state="OPENING"],
  &[data-render-state="VISIBLE"] {
    pointer-events: auto;

    > .Backdrop {
      filter: opacity(1);
    }

    > .Content {
      transform: translateY(0vh);
    }
  }

  &[data-render-state="OPENING"],
  &[data-render-state="VISIBLE"],
  &[data-render-state="CLOSING"] {
    opacity: 1;
  }

  &[data-render-state="CLOSING"] {
    pointer-events: auto;
  }
}
