.Field {
  place-content: center;
  place-items: center;
  padding: 1em 1em 2em 1em;
  width: 100%;
  height: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto 1fr auto;
  grid-gap: 1em;
  grid-template-areas:
    "logo logo settings"
    "coin coin coin"
    "active active active"
    "bench bench bench";
  transform-origin: center bottom;
  filter: blur(0px) opacity(1);
  --border-radius: 12px;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  transform: scale(1);
  transition-property: transform, filter, border-radius;
  transition-duration: var(--modal-animation-duration);
  transition-timing-function: ease;

  &[data-modal-open="true"] {
    filter: blur(2px) opacity(0.75);
    transform: scale(0.98) translateY(1em);
  }

  > .LogoSpot {
    grid-area: logo;
    justify-self: start;
    width: 100%;
  }

  > .SettingsSpot {
    grid-area: settings;
    justify-self: end;
    align-self: start;
  }

  > .CoinSpot {
    grid-area: coin;
    justify-self: center;
  }

  > .ActiveSpot {
    grid-area: active;
    width: 100%;
  }

  > .Bench {
    width: 100%;
    align-self: end;
    display: grid;
    grid-area: bench;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;
    grid-gap: 10px;
    align-items: end;
  }
}
