*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  user-select: none;
  cursor: default;
}

html {
  width: 100vw;
  height: 100dvh;
}

body {
  --modal-animation-duration: 400ms;
  --damage-counter-10: #ffa500;
  --damage-counter-50: #ff5733;
  --damage-counter-100: #dc143c;
  line-height: 1;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div#root {
  position: relative;
  z-index: 0;
  isolation: isolate;
  width: 100%;
  height: 100%;
}

div#modal-root {
  position: relative;
  z-index: 1;
  isolation: isolate;
}

input {
  cursor: initial;
}

.Button {
  * {
    cursor: inherit;
  }
}
