.DoDamage {
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  > .Backdrop {
    width: 100%;
    height: 100;
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  > .Content {
    position: relative;
    z-index: 1;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);

    > .CustomDamage {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 1em;
      align-items: center;
      border: 1px solid silver;
      border-radius: 1em;
      padding: 1em;
      > input {
        text-align: right;
        font-family: monospace;
        width: 5ch;
        font-size: 2em;
        border: none;
        border-bottom: 2px dotted silver;
      }
    }

    > .PresetButtons {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 1fr;
      grid-gap: 1em;
      margin: 1em 0;
    }

    > .CancelButton {
      margin-top: 1em;
    }
  }
}
