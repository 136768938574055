.ActiveCardThumbnail {
  position: relative;
  background-color: silver;
  background-size: cover;
  width: 100%;
  max-width: 480px;
  height: 100%;
  padding: 1.5em;
  cursor: pointer;
  border-radius: 1em;
  box-shadow: inset -2px -2px 0 0 rgba(0, 0, 0, 0.32),
    inset 2px 2px 0 0 rgba(255, 255, 255, 0.5), 0 2px 8px 0 rgba(0, 0, 0, 0.25);

  > .Content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-gap: 0.5em;

    > .RemainingHP {
      z-index: 1;
      font-size: 18pt;
      position: absolute;
      top: -2ch;
      font-weight: 800;
      width: 100%;
      text-align: center;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        width: 4ch;
        height: 4ch;
        border-radius: 50%;
        background-color: #2ec731;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        text-shadow: 1px 1px 0 black;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);

        &[data-criticality="danger"] {
          background-color: orange;
        }

        &[data-criticality="critical"] {
          background-color: red;
        }
      }
    }

    header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > .SpecialConditions {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        img {
          width: 24px;
          height: 24px;
          margin-right: 0.5rem;
        }
      }

      > .Space {
        flex: 1;
      }

      > .HP {
        font-size: 16pt;
        font-weight: 700;
        text-align: center;
        margin-right: 0.5rem;
        line-height: 1;
      }
    }

    > .HPDamageIndicator {
      display: flex;
      align-items: center;

      > .HPBar {
        position: relative;
        width: 100%;
        height: 1.25em;
        padding: 3px;
        border-radius: 0.35em;
        border: 2px solid rgba(255, 255, 255, 0.75);

        > .BarBackground {
          position: relative;
          border-radius: 0.2em;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.25);
          overflow: hidden;

          > .Bar {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: #2ec731;

            &[data-criticality="danger"] {
              background-color: orange;
            }

            &[data-criticality="critical"] {
              background-color: red;
            }
          }
        }
      }
    }
  }
}
