.DamageCounterControl {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > .Count {
    width: 100%;
    flex: 1;
    font-weight: 600;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    > .Counters {
      position: relative;
      font-size: 8pt;
      text-align: center;
      line-height: 1;
      position: relative;
      display: flex;
      margin-top: 0.5rem;
      align-items: center;
      justify-content: space-evenly;
      left: -1.5ch;
      padding: 0 1rem;
      pointer-events: none;
      width: 100%;
      max-width: 20ch;

      > .Counter {
        height: 3ch;
        font-weight: 800;

        > div {
          position: absolute;
          display: flex;
          width: 3ch;
          height: inherit;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          box-shadow: -1px 1px 1px 1px rgba(0, 0, 0, 0.25);
          color: white;
        }

        &[data-value="10"] > div {
          background-color: var(--damage-counter-10);
        }
        &[data-value="50"] > div {
          background-color: var(--damage-counter-50);
        }
        &[data-value="100"] > div {
          background-color: var(--damage-counter-100);
        }
      }
    }
  }
}
