.Element--Popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  filter: opacity(0);
  transition-property: filter;
  transition-duration: 200ms;
  transition-timing-function: ease;

  > .Backdrop {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  > .Content {
    position: relative;
    z-index: 1;
    transform: scale(0.9);
    transition-property: transform;
    transition-duration: 200ms;
    transition-timing-function: ease;
  }

  &[data-open="true"] {
    pointer-events: auto;
    filter: opacity(1);

    > .Content {
      transform: scale(1);
      transition-timing-function: cubic-bezier(0, 3, 1, 1);
    }
  }
}
