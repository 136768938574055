.Element--Section {
  position: relative;
  padding: var(--spacing);
  border-radius: var(--border-radius);
  box-shadow: -2px 1px 2px 0 rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 0.1);

  &::before {
    z-index: 0;
    border-radius: var(--border-radius);
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 1);
    mix-blend-mode: soft-light;
    opacity: 0.75;
    pointer-events: none;
  }
}
