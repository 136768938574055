.CardCreator {
  --font-size: 1.25em;
  --spacing: 1em;
  --border-radius: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12pt;
  padding: 1em;

  > .Content {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    grid-gap: var(--spacing);
    overflow: scroll;
    padding: 1em;
    border-radius: 1em;
    max-height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset -2px -2px 0 0 rgba(0, 0, 0, 0.5),
      inset 2px 2px 0 0 rgba(255, 255, 255, 0.5),
      0 2px 4px 0 rgba(0, 0, 0, 0.25);

    > .Buttons {
      z-index: 1;
      align-self: end;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      position: sticky;
      bottom: 0;
      align-items: center;
    }
  }
}
