.DamageIndicator {
  box-sizing: content-box;
  color: white;
  font-weight: 800;
  padding: 10px;
  width: 2.25ch;
  height: 2.25ch;
  border-radius: 50%;
  box-shadow: inset 2px 2px 0 0 rgba(255, 255, 255, 0.35),
    inset -2px -2px 0 0 rgba(0, 0, 0, 0.25);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 1px 1px 0 black;
  font-weight: 900;

  &[data-threshold="none"] {
    background-color: silver;
  }

  &[data-threshold="yellow"] {
    background-color: var(--damage-counter-10);
  }

  &[data-threshold="orange"] {
    background-color: var(--damage-counter-50);
  }

  &[data-threshold="red"] {
    background-color: var(--damage-counter-100);
  }

  &[data-variant="heal"] {
    &[data-threshold="yellow"] {
      background-color: #4682b4;
    }

    &[data-threshold="orange"] {
      background-color: #20b2aa;
    }

    &[data-threshold="red"] {
      background-color: #9acd32;
    }
  }
}
