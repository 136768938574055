.BenchCardThumbnail {
  position: relative;
  background-color: silver;
  width: 100%;
  height: 100%;
  padding: 10px;
  cursor: pointer;
  border-radius: 1em;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  box-shadow: inset -2px -2px 0 0 rgba(0, 0, 0, 0.25),
    inset 2px 2px 0 0 rgba(255, 255, 255, 0.5), 0 2px 8px 0 rgba(0, 0, 0, 0.25);

  > .Content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-gap: 10px;
    place-items: center;
    place-content: center;

    > .Type {
      display: flex;
      justify-content: center;
    }

    > .HP {
      font-weight: 700;
      text-align: center;
      padding: 0 0.25em;

      > .Remaining {
        font-size: 1.2em;
      }
      > .Max {
        font-size: 0.85rem;
        text-align: right;
      }
    }
  }

  > .SpecialConditions {
    position: absolute;
    top: -12px;
    width: 100%;
    left: 0;
    right: 0;
    padding: 0 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    img {
      width: 24px;
      height: 24px;
    }
  }
}
