.EmptySpot {
  border: 2px dotted silver;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  place-content: center;
  place-items: center;
  color: silver;
  padding: 20px;
  min-height: 120px;
  font-weight: 800;

  * {
    cursor: pointer;
  }
}
