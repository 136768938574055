.TypePicker {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > .Item {
    width: calc(100% / 6);

    .Option {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.25em;
      border-radius: 50%;
      border: 2px solid transparent;

      &[data-selected="true"] {
        border: 2px solid;
        transform: scale(1.25);
      }
    }
  }
}
