.CardEditor {
  --font-size: 1.25em;
  --spacing: 1em;
  --border-radius: 1em;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing);
  font-size: 12pt;
  padding: 1em;

  > .Content {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto 1fr;
    grid-gap: var(--spacing);
    overflow: scroll;
    padding: 1em;
    border-radius: 1em;
    max-height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset -2px -2px 0 0 rgba(0, 0, 0, 0.5),
      inset 2px 2px 0 0 rgba(255, 255, 255, 0.5),
      0 2px 4px 0 rgba(0, 0, 0, 0.25);

    > .HP {
      border-radius: 10px;
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: space-between;
      align-items: center;

      .Display {
        font-weight: bold;

        .Value {
          font-size: var(--font-size);
        }
      }
    }

    .HPPickerBox {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      > .DoneButton {
        position: sticky;
        bottom: 0;
        margin-top: var(--spacing);
      }
    }

    > .DamageCounters {
      width: 100%;
    }

    > .SpecialConditions {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 1em;
      justify-content: center;

      .SpecialConditionToggle {
        filter: opacity(0.5) grayscale(100%);
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14pt;

        &[data-selected="true"] {
          opacity: 1;
          filter: opacity(1) grayscale(0%);
        }

        &.Poisoned {
          color: #2aaa68;
        }
        &.Burned {
          color: #dc3b38;
        }

        img {
          display: block;
          width: 1.5em;
          height: 1.5em;
          margin-right: 0.5em;
        }
      }
    }

    > .Move {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: auto auto auto;
      grid-template-areas:
        "header header header header header "
        ". activeSpot activeSpot activeSpot ."
        "bench1 bench2 bench3 bench4 bench5";
      place-items: center;
      place-content: center;
      grid-gap: 20px;
    }

    > .Buttons {
      align-self: end;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      position: sticky;
      bottom: 0;
      align-items: center;
    }
  }
}
