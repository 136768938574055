.Element--Button {
  position: relative;
  font-size: 10pt;
  padding: 1em;
  border-radius: 0.5em;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  > * {
    position: relative;
    z-index: 1;
  }

  &::before {
    z-index: 0;
    border-radius: inherit;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 1);
    mix-blend-mode: soft-light;
    opacity: 0.75;
    pointer-events: none;
  }

  &:active {
    opacity: 0.5;
  }

  &[data-disabled="true"] {
    pointer-events: none;
    filter: opacity(0.5) grayscale(100%);
  }

  &[data-size="small"] {
    font-size: 10pt;
    font-weight: 500;
  }

  &[data-size="normal"] {
    font-size: 12pt;
    font-weight: 400;
  }

  &[data-size="large"] {
    font-size: 14pt;
    font-weight: 400;
  }

  &[data-variant="delete"] {
    color: red;

    &::before {
      background-color: rgba(30, 30, 30, 0.5);
    }
  }

  &[data-variant="custom"] {
    background-color: transparent;
    padding: 0;
    border-radius: 0;

    &::before {
      content: unset;
    }
  }

  &[data-variant="gray"] {
    background-color: rgba(230, 230, 230, 0.5);

    &::before {
      content: unset;
    }

    &:active {
      background-color: rgba(100, 100, 100, 0.5);
    }
  }

  * {
    cursor: inherit;
  }
}
