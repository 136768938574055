.Coin {
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  > div {
    pointer-events: none;
    transform: scale(1);
    transition-property: transform, box-shadow;
    transition-duration: 400ms;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0), 0 0 0 0 rgba(255, 255, 255, 0);
    transition-timing-function: cubic-bezier(0.75, -0.5, 0, 6);
    border-radius: 50%;
  }

  &:active {
    > div {
      transform: scale(0.75);
      box-shadow: 0 0 2em 2em rgba(255, 255, 255, 0.75),
        0 0 0 2em rgba(0, 0, 0, 0.25);
      transition-duration: 200ms, 5s;
      transition-timing-function: ease;
    }
  }

  .CoinFaces {
    position: relative;
    margin: 0 auto;
    width: 5em;
    height: 5em;
    cursor: pointer;
    transform: rotateY(0deg);
    transform-style: preserve-3d;
    transition-property: transform;
    transition-timing-function: ease-out;
    pointer-events: none;

    > div {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      position: absolute;
      backface-visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        display: block;
        width: 75%;
        height: 75%;
      }

      &.Heads {
        background-color: gold;
        box-shadow: inset -2px -2px 0 2px #febe10,
          inset 2px 2px 0 2px rgb(255, 255, 200);
        z-index: 100;
      }
      &.Tails {
        background-color: silver;
        box-shadow: inset -2px -2px 0 2px slategray, inset 2px 2px 0 2px #e4e7eb;
        transform: rotateY(-180deg);
      }
    }
  }
}
