.Logo {
  position: relative;
  grid-area: logo;
  justify-self: start;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  text-align: left;

  > h1 {
    font-size: 1em;
    font-weight: 500;
    > .Name {
      font-weight: 900;
    }
    > .Domain {
      color: #e50013;
    }
  }

  > p {
    position: absolute;
    top: 2em;
    left: 0;
    color: silver;
    line-height: 1.4;
  }
}
